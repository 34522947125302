





import { Component, Prop, Vue } from 'vue-property-decorator';
// 条款与细则
import conditions from './conditions.vue';
// 违禁品说明
import contraband from './contraband.vue';
// 免责声明
import disclaimers from './disclaimers.vue';
// 隐私保护声明
import privacyProtectionInstructions from './privacy-protection-instructions.vue';

enum agreementType {
    '条款与细则' = 'conditions',
    '违禁品说明' = 'contraband',
    '免责声明' = 'disclaimers',
    '隐私保护声明' = 'privacyProtectionInstructions',
}

@Component({
    name: 'Agreement',
    components: {
        conditions,
        contraband,
        disclaimers,
        privacyProtectionInstructions
    }
})
export default class Agreement extends Vue {
    @Prop({
        type: String,
        default: () => '免责声明'
    })
    public show: agreementType;

    public component: any = agreementType;
}
