import { render, staticRenderFns } from "./privacy-protection-instructions.vue?vue&type=template&id=0d070711&scoped=true&"
import script from "./privacy-protection-instructions.vue?vue&type=script&lang=ts&"
export * from "./privacy-protection-instructions.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0d070711",
  null
  
)

export default component.exports